/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import AudiIcon from 'Style/icons/logos/audi.png';
import MollerIcon from 'Style/icons/logos/moller-logo.png';
import OutletIcon from 'Style/icons/logos/outlet.png';
import SkodaVWAudiServiceIcon from 'Style/icons/logos/skoda-vw-audi-service.png';
import SkodaVWIcon from 'Style/icons/logos/skoda-vw.png';
import VolkswagenIcon from 'Style/icons/logos/volkswagen.png';
import VWAudiService from 'Style/icons/logos/vw-audi-service.png';

import MailIcon from '../../Icons/EnvelopeSimple.svg';
import MapIcon from '../../Icons/MapPin.svg';
import PhoneIcon from '../../Icons/Phone.svg';
import QuestionIcon from '../../Icons/Question.svg';

export const STORES = 'stores';
export const POSITION = 'position';
export const CONTACT_US_FORM = 'contact-us-form';
export const STORE_CARD_ICONS = {
    mapIcon: { src: MapIcon, alt: 'Map pin icon' },
    mailIcon: { src: MailIcon, alt: 'Envelope icon' },
    phoneIcon: { src: PhoneIcon, alt: 'Phone icon' },
    questionIcon: { src: QuestionIcon, alt: 'Question icon' }
};

export const DEALERSHIP_ICONS = {
    audi: { src: AudiIcon, alt: 'Audi icon' },
    volkswagen: { src: VolkswagenIcon, alt: 'Wolksvagen icon' },
    outlet: { src: OutletIcon, alt: 'Outlet icon' },
    moller: { src: MollerIcon, alt: 'Moller icon' },
    skodavw: { src: SkodaVWIcon, alt: 'Skoda and Volkswagen icon' },
    skodavwaudi: { src: SkodaVWAudiServiceIcon, alt: 'Skoda, Volkswagen and Audi service icon' },
    vwaudi: { src: VWAudiService, alt: 'Volkswagen and audi service icon' }
};

export const DEALERSHIPS = {
    riga: DEALERSHIP_ICONS.audi,
    outlet: DEALERSHIP_ICONS.outlet,
    jelgava: DEALERSHIP_ICONS.moller,
    'lidosta-audi': DEALERSHIP_ICONS.audi,
    'zedai-audi': DEALERSHIP_ICONS.audi,
    'lidosta-vw': DEALERSHIP_ICONS.volkswagen,
    'krasta-vw': DEALERSHIP_ICONS.vwaudi,
    'ventspils-vw': DEALERSHIP_ICONS.volkswagen,
    'vilnius-vw': DEALERSHIP_ICONS.volkswagen,
    'savanoriai-vw': DEALERSHIP_ICONS.volkswagen,
    mustamae: DEALERSHIP_ICONS.volkswagen,
    ulemiste: DEALERSHIP_ICONS.volkswagen,
    parnu: DEALERSHIP_ICONS.skodavw,
    viru: DEALERSHIP_ICONS.skodavwaudi
};

export const META_TITLES = {
    lv: 'Dīleru saraksts - Møller Auto Latvija',
    lv_ru: 'Поиск дилеров - Møller Auto Latvija',
    lv_en: 'Dealership locator - Møller Auto Latvia',
    lt: 'Atstovybės paieška - Møller Auto Lietuva',
    lt_ru: 'Поиск дилеров - Møller Auto Lietuva',
    lt_en: 'Dealership locator - Møller Auto Lithuania',
    ee: 'Esinduse otsing - Møller Auto Eesti',
    ee_ru: 'Поиск дилеров - Møller Auto Eesti',
    ee_en: 'Dealership locator - Møller Auto Estonia'
};

export const META_DESCRIPTIONS = {
    lv: 'Atrodi tuvāko Møller Auto Dīleri un uzzini to darba laiku un kontaktinformāciju.',
    lv_ru: 'Найдите все официальные дилерские центры Møller Auto, а также их контакты и часы работы.',
    lv_en: 'Find all of the Møller Auto Official Dealerships as well as their contacts and working hours.',
    lt: 'Raskite visas oficialias Møller Auto atstovybes, jų kontaktus ir darbo laiką.',
    lt_ru: 'Найдите все официальные дилерские центры Møller Auto, а также их контакты и часы работы.',
    lt_en: 'Find all of the Møller Auto Official Dealerships as well as their contacts and working hours.',
    ee: 'Leidke kõik Møller Auto ametlikud edasimüüjad ning nende kontaktid ja tööajad.',
    ee_ru: 'Найдите все официальные дилерские центры Møller Auto, а также их контакты и часы работы.',
    ee_en: 'Find all of the Møller Auto Official Dealerships as well as their contacts and working hours.'
};

export const GET_DIRECTIONS_BASE_URL = 'https://www.google.com/maps?q=';

/** @namespace Storefinder/Route/StoreFinder/Config/getAllCitiesText */
export const getAllCitiesText = () => __('All cities');

/** @namespace Storefinder/Route/StoreFinder/Config/getAllDealershipsText */
export const getAllDealershipsText = () => __('All dealerships');
