export const FORM_WIDGET = 'flexibleforms';

export const FLEXIBLEFORMS_FORM_HANDLER = '/flexibleforms/index/result/';

export const FLEXIBLEFORM_ENABLED = '1';
export const FLEXIBLEFORM_DISABLED = '0';

export const BLOCK_NAME = 'FlexibleForms';

export const STAR_NUMBERS = 5;

export const COMPARABLE_FIELD_CLASS = 'comparable';

export const RESPONSE_CODES = {
    badRequest: 400,
    serviceUnavailable: 503
};
export const SUCCESS_POPUP_ID = 'flexibleFormPopup';

export const CAR_URL_TITLES = ['The car URL', 'Automašīnas URL', 'Automobilio URL', 'Auto URL', 'Ссылка автомобиля'];

export const XML_TO_FORMS = {
    'Moller Auto Rīga': 'Møller Auto Rīga',
    'Moller Auto Outlet': 'Møller Auto Outlet',
    'Moller Auto Jelgava': 'Møller Auto Jelgava',
    'Moller Auto Lidosta Audi': 'Møller Auto Lidosta Audi',
    'Moller Auto Lidosta Volkswagen': 'Møller Auto Lidosta VW',
    'Moller Auto Krasta': 'Møller Auto Krasta',
    'Moller Auto Ventspils': 'Møller Auto Ventspils',
    'Moller Auto Vilnius': 'Møller Auto Vilnius',
    'Moller Auto Keturi žiedai': 'Møller Auto Keturi Žiedai',
    'Moller Auto Savanoriai': 'Møller Auto Savanoriai',
    'Moller Auto Mustamäe': 'Møller Auto Mustamäe',
    'Moller Auto Ülemiste': 'Møller Auto Ülemiste',
    'Moller Auto Pärnu': 'Møller Auto Pärnu',
    'Moller Auto Viru': 'Møller Auto Viru'
};
