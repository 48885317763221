export * from 'SourceComponent/Header/Header.config';

export const COMPARE = 'compare';

export const AboutUsRoutes = {
    lv_en: 'about-us-lv-en',
    lv: 'about-us-lv',
    lv_ru: 'about-us-lv-ru',
    lt: 'about-us-lt',
    lt_en: 'about-us-lt-en',
    lt_ru: 'about-us-lt-ru',
    ee: 'about-us-ee',
    ee_en: 'about-us-ee-en',
    ee_ru: 'about-us-ee-ru'
};

export const CareerLinks = {
    lv: 'http://career.mollerauto.lv/lv',
    lv_en: 'http://career.mollerauto.lv',
    lv_ru: 'http://career.mollerauto.lv',
    lt: 'http://career.mollerauto.lv/lt',
    lt_en: 'http://career.mollerauto.lv',
    lt_ru: 'http://career.mollerauto.lv',
    ee: 'http://career.mollerauto.lv/et',
    ee_en: 'http://career.mollerauto.lv',
    ee_ru: 'http://career.mollerauto.lv'
};

export const MobileClubLinks = {
    lv: 'https://mollerauto.lv/mollerclub-early-birds',
    lv_en: 'https://mollerauto.lv/lv_en/mollerclub_eng',
    lv_ru: 'https://mollerauto.lv/mollerclub-early-birds',
    lt: 'https://mollerauto.lt/mollerclub-early-birds-lt',
    lt_en: 'https://mollerauto.lt/mollerclub-early-birds-lt',
    lt_ru: 'https://mollerauto.lt/mollerclub-early-birds-lt',
    ee: 'https://mollerauto.ee/mollerclub-early-birds-ee',
    ee_en: 'https://mollerauto.ee/mollerclub-early-birds-ee',
    ee_ru: 'https://mollerauto.ee/mollerclub-early-birds-ee'
};
