/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const DL_VAL_NOT_APPLICABLE = 'N/A';
export const CURRENCY_CODE = 'EUR';
export const LANGUAGES = {
    lv_LV: 'Latvian',
    lt_LT: 'Lithuanian',
    ee_EE: 'Estonian',
    en_US: 'English',
    ru_RU: 'Russian'
};
export const DEALERSHIP_NAMES = {
    ulemiste: 'Møller Auto Ülemiste',
    viru: 'Møller Auto Viru',
    parnu: 'Møller Auto Pärnu',
    mustamae: 'Møller Auto Mustamäe',
    'vilnius-vw': 'Møller Auto Vilnius Volkswagen',
    'savanoriai-vw': 'Møller Auto Savanoriai VW',
    'zedai-audi': 'Møller Auto Keturi Žiedai Audi',
    'krasta-vw': 'Møller Auto Krasta Volkswagen',
    'lidosta-vw': 'Møller Auto Lidosta Volkswagen',
    'ventspils-vw': 'Møller Auto Ventspils Volkswagen',
    riga: 'Møller Auto Rīga',
    outlet: 'Møller Auto Outlet',
    jelgava: 'Møller Auto Jelgava',
    'lidosta-audi': 'Møller Auto Lidosta Audi'
};
export const ATTRIBUTE_CODES = [
    'make', 'model', 'first_registered', 'mileage', 'body_style',
    'displacement', 'fuel', 'power', 'gearbox', 'gearbox_search',
    'drivetrain_suspension', 'drivetrain_search', 'car_color',
    'technical_inspection', 'equipment_search', 'dealer'
];
